const React = require('react');
const { string, array, oneOf } = require('prop-types');
const { useMemo, useState, useEffect } = require('react');
const CarouselSnapped = require('@andes/carousel-snapped');
const { CarouselSnappedSlide } = require('@andes/carousel-snapped');
const DynamicAccessCardDesktop = require('./dynamic-access-card/desktop');
const withTracker = require('../with-tracker');
const adjustCardHeightDesktop = require('./utils/height-in-cards-desktop');
const { slidesInDynamicAccess } = require('../../../../utils/slider-per-view');

const DynamicAccessDesktop = ({ type, cards, deviceType, viewportWidth, title }) => {
  const sizeCard = useMemo(() => adjustCardHeightDesktop(cards), [cards]);
  const [newViewportWidth, setNewViewportWidth] = useState(viewportWidth || 6);

  useEffect(() => {
    setNewViewportWidth(window.innerWidth);
    const handleResize = () => {
      setNewViewportWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <section type={type} className="dynamic-access">
      <div className="container row">
        <CarouselSnapped
          srLabel={title}
          slidesPerView={slidesInDynamicAccess(newViewportWidth)}
          className={`carousel-dynamic-access-${deviceType}`}
          spacing={16}
          pagination={false}
          lazyload={false}
          arrows
        >
          {cards.map((card, index) => (
            <CarouselSnappedSlide key={card.card_id}>
              <DynamicAccessCardDesktop
                card={card}
                sizeCard={sizeCard}
                index={index}
              />
            </CarouselSnappedSlide>
          ))}
        </CarouselSnapped>
      </div>
    </section>
  );
};

DynamicAccessDesktop.propTypes = {
  type: string,
  cards: array,
  deviceType: oneOf(['desktop', 'mobile', 'tablet']),
  viewportWidth: string,
  title: string,
};

module.exports = withTracker(DynamicAccessDesktop);

const React = require('react');
const { useState } = require('react');
const classnames = require('classnames');
const { shape, object, string, bool } = require('prop-types');
const { Button } = require('@andes/button');
const AndesCard = require('@andes/card');
const Spinner = require('@andes/spinner');
const Modal = require('@andes/modal');
const withTracker = require('../../../with-tracker');
const withTrackerOnPrint = require('../../../with-tracker-on-print');
const { trackEvent } = require('../../../../../services/tracking');
const CustomAction = require('../../utils/custom-action');
const Iframe = require('../../../iframe');
const CustomIframeModal = require('../../../custom-iframe-modal/custom-iframe-modal');
const IconLocation = require('../../../icons/dynamic-access/location');
const IconLowPriceproducts = require('../../../icons/dynamic-access/low-price-product');
const IconMlbLowPriceproducts = require('../../../icons/dynamic-access/mlb-low-price-product');
const IconProtectedBuy = require('../../../icons/dynamic-access/protected-buy');
const IconOfficialStore = require('../../../icons/dynamic-access/official-store');
const IconCategories = require('../../../icons/dynamic-access/categories');
const IconPeymentMethods = require('../../../icons/dynamic-access/payment-methods');
const IconPregrstration = require('../../../icons/dynamic-access/registration');
const IconTopSales = require('../../../icons/dynamic-access/top-sale');
const IconHelp = require('../../../icons/dynamic-access/help');
const IconCellphoneCategory = require('../../../icons/dynamic-access/cellphones-category');
const IconShoesCategory = require('../../../icons/dynamic-access/shoes-category');
const IconMpeLowPriceproducts = require('../../../icons/dynamic-access/mpe-low-price-products');
const IconSellWithUs = require('../../../icons/dynamic-access/sell-with-us');
const IconNewBuyer = require('../../../icons/dynamic-access/new-buyers');
const IconFactory = require('../../../icons/factory-builder')([
  IconLocation,
  IconLowPriceproducts,
  IconMlbLowPriceproducts,
  IconProtectedBuy,
  IconOfficialStore,
  IconCategories,
  IconPeymentMethods,
  IconPregrstration,
  IconTopSales,
  IconHelp,
  IconShoesCategory,
  IconCellphoneCategory,
  IconMpeLowPriceproducts,
  IconSellWithUs,
  IconNewBuyer,
]);
const {
  STATIC_ILLUSTRATION,
  STATIC_ICON,
  CARD_CUSTOM_IFRAME_MODAL,
} = require('../../../../../../utils/constants');

const namespace = 'dynamic-access-card';

const CardIcon = ({ card, track, type, sizeCard, isCardModal, isCustomCard, cardId }) => {
  const {
    title,
    picture,
    description,
    action,
    modal = {},
    message = '',
  } = card;
  const { label, target } = action;
  const {
    title_modal: titleModal,
    text_close: textClose,
    target_modal: targetModal,
    hash_url: hashUrl = '',
  } = modal;

  const isCustomIframeModal = CARD_CUSTOM_IFRAME_MODAL.includes(cardId);

  const handleClick = () => {
    trackEvent(track);
  };
  const cardName = `${namespace}-ecosistemic`;
  const actionProps = {
    className: `${cardName}__action`,
    onClick: handleClick,
  };

  const [isOpen, setisOpen] = useState(false);
  const [isLoading, setisLoading] = useState(false);

  /* istanbul ignore next */
  const openModal = (e) => {
    if (!e.ctrlKey && !e.metaKey) {
      e.preventDefault();
      setisOpen(true);
      setisLoading(true);
    } else {
      window.open(target, '_blank');
    }
  };

  /* istanbul ignore next */
  const onCloseModal = () => {
    setisOpen(false);
  };

  /* istanbul ignore next */
  const onIframeLoad = (e) => {
    if (!isCustomIframeModal) {
      const iframe = e.target;
      iframe.style.height = `${iframe.contentWindow.document.body.scrollHeight}px`;
      if (hashUrl && iframe.contentWindow.document.querySelector(hashUrl)) {
        const modalScroll = document.querySelector('.andes-modal__scroll');
        modalScroll.scrollTop = iframe.contentWindow.document.querySelector(hashUrl).offsetTop;
      }
    }

    setisLoading(false);
  };

  return (
    <AndesCard
      className={classnames(namespace, 'dynamic-access-card-common', `${namespace}__${sizeCard}`, {
        'dynamic-access-card-icon': type === STATIC_ICON,
        'dynamic-access-card-ilustrator': type === STATIC_ILLUSTRATION,
      })}
    >
      <div className={cardName}>
        <div className={`${cardName}-header`}>
          {title && <h2 className={`${cardName}__title`}>{title.text}</h2>}
        </div>
        {picture && (
          <div className={`${cardName}-icon`} aria-hidden="true">
            {IconFactory(picture.id, { deviceType: 'desktop', alt: title?.text })}
          </div>
        )}
        {description && (
          <div className={`${cardName}-description`}>
            <span className={`${cardName}__description`}>
              {description.text}
            </span>
          </div>
        )}
        <div className={`${cardName}-footer`}>
          {!isCardModal ? (
            <CustomAction
              cardName={cardName}
              target={target}
              label={label.text}
              isCustomCard={isCustomCard}
              message={message}
              actionProps={actionProps}
              accesibilityText={title?.text}
            />
          ) : (
            <>
              <Button
                hierarchy="transparent"
                size="small"
                fullWidth
                label={label.text}
                className={`${cardName}__action`}
                onClick={openModal}
              >
                {label.text}
                {title && <span className="andes-visually-hidden">{title.text}</span>}
              </Button>
              <Modal
                open={isOpen}
                onClose={onCloseModal}
                type="large"
                maxWidth={840}
                title={titleModal}
                className={classnames('modal', {
                  iframeScroll: isCustomIframeModal,
                })}
                closeButtonSrLabel={textClose}
                url="#show-modal"
              >
                <Spinner modifier="fullscreen" size="large" show={isLoading} />
                {
                  isCustomIframeModal ? (
                    <CustomIframeModal
                      targetModal={targetModal}
                      onLoad={onIframeLoad}
                      cardId={cardId}
                    />
                  ) : (
                    <Iframe
                      title={titleModal}
                      src={targetModal}
                      onLoad={onIframeLoad}
                      scrolling="no"
                    />
                  )
                }
              </Modal>
            </>
          )}
        </div>
      </div>
    </AndesCard>
  );
};

CardIcon.propTypes = {
  card: shape({
    content: object,
  }),
  track: object,
  type: string,
  sizeCard: string,
  isCardModal: bool,
  message: string,
  isCustomCard: bool,
};

module.exports = withTracker(withTrackerOnPrint(CardIcon));

const React = require('react');
const { string } = require('prop-types');
const { Image } = require('nordic/image');
const classnames = require('classnames');

const ICON_ID = 'home-da-registration';
const namespace = 'ui-homes-icon ui-homes-icon--da-registration';

const IconRegistration = ({ className, deviceType, alt }) => (
  <Image
    className={classnames(namespace, className)}
    src={`dynamic-access-${deviceType}/registration-da.svg`}
    alt={alt}
    lazyload="off"
    preload
  />
);

IconRegistration.propTypes = {
  className: string,
  deviceType: string,
  alt: string,
};

IconRegistration.defaultProps = {
  className: null,
  deviceType: 'desktop',
  alt: '',
};

module.exports = React.memo(IconRegistration);
module.exports.ICON_ID = ICON_ID;

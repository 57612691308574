const React = require('react');
const { object, string, shape } = require('prop-types');
const CardIconDesktop = require('./cards/card-icon-desktop');
const CardItemDesktop = require('./cards/card-item-desktop');
const CardMediaDesktop = require('./cards/card-media-desktop');

const {
  STATIC_ITEM,
  STATIC_ICON,
  STATIC_MEDIA,
  STATIC_ILLUSTRATION,
  ITEMS_IMAGES_GRID,
  DYNAMIC_ACCESS_MODAL,
  DYNAMIC_ACCESS_CUSTOM_CARD,
} = require('../../../../../utils/constants');

const DynamicAccessCardDesktop = ({ card, sizeCard, index }) => {
  const { card_id: cardId, content, reco_track, track, type } = card;
  const isCardModal = DYNAMIC_ACCESS_MODAL.includes(cardId);
  const isCustomCard = DYNAMIC_ACCESS_CUSTOM_CARD.includes(cardId);
  switch (type) {
    case STATIC_ITEM:
    case ITEMS_IMAGES_GRID: {
      return (
        <CardItemDesktop
          card={content}
          reco_track={reco_track}
          track={track}
          key={cardId}
          sizeCard={sizeCard}
          index={index}
        />
      );
    }
    case STATIC_ICON:
    case STATIC_ILLUSTRATION: {
      return (
        <CardIconDesktop
          card={content}
          reco_track={reco_track}
          track={track}
          key={cardId}
          cardId={cardId}
          isCardModal={isCardModal}
          isCustomCard={isCustomCard}
          type={type}
          sizeCard={sizeCard}
        />
      );
    }
    case STATIC_MEDIA: {
      return (
        <CardMediaDesktop
          card={content}
          reco_track={reco_track}
          track={track}
          key={cardId}
          isCardModal={isCardModal}
          isCustomCard={isCustomCard}
          type={type}
          sizeCard={sizeCard}
        />
      );
    }
    default:
      return null;
  }
};

DynamicAccessCardDesktop.propTypes = {
  card: shape({
    type: string,
    card_id: string,
    content: object,
    track: object,
    reco_track: object,
  }),
  sizeCard: string,
};

module.exports = DynamicAccessCardDesktop;

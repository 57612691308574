const {
  STATIC_ITEM,
  DYNAMIC_ACCESS_SIZE,
  ITEMS_IMAGES_GRID,
} = require('../../../../../utils/constants');

const { SMALL, MEDIUM, LONG } = DYNAMIC_ACCESS_SIZE;

const getSize = (isLongPrince, isMedium) => {
  if (isLongPrince) return LONG;
  if (isMedium) return MEDIUM;
  return SMALL;
};

const adjustCardHeight = (cards) => {
  const items = cards.filter(key => key.type === STATIC_ITEM || key.type === ITEMS_IMAGES_GRID);
  const { isLongPrince, isMedium } = items.reduce(
    (acc, key) => {
      if (key.type === ITEMS_IMAGES_GRID) acc.isMedium = true;
      if (key.type === STATIC_ITEM) {
        if (key?.content?.action || key?.content?.shipping?.subText) {
          acc.isMedium = true;
        }
        const lengthPrice = key?.content?.price?.current_value?.value?.fraction?.length;
        acc.isLongPrince = acc.isLongPrince || (key?.content?.price?.discount && lengthPrice >= 10);
      }
      return acc;
    },
    { isLongPrince: false, isMedium: false },
  );

  return getSize(isLongPrince, isMedium);
};

module.exports = adjustCardHeight;

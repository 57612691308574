const React = require('react');

const { string } = require('prop-types');

const PaymentsMethodsV2 = require('../payments-methods/payments-methods-v2');

const CustomIframeModal = ({ cardId, ...rest }) => {
  switch (cardId) {
    case 'payment_methods_v2':
      return <PaymentsMethodsV2 {...rest} />;
    default:
      return null;
  }
};

CustomIframeModal.propTypes = {
  cardId: string.isRequired,
};

CustomIframeModal.defaultProps = {
  cardId: null,
};

module.exports = React.memo(CustomIframeModal);

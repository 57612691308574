const slidesPerViewInRecos = (isDualCarousel, deviceType) => {
  if (deviceType === 'desktop') {
    return isDualCarousel ? 4 : 6;
  }
  if (deviceType === 'tablet') {
    return isDualCarousel ? 3 : 5;
  }
  return 5;
};

const slidesPerViewIncomponents = (viewportWidth) => {
  const viewport = parseInt(viewportWidth, 10);
  return viewport > 1600 ? 7 : 6;
};
const slidesInDynamicAccess = (viewportWidth) => {
  const viewport = parseInt(viewportWidth, 10);
  return viewport >= 1180 ? 6 : 5;
};

module.exports = { slidesPerViewInRecos, slidesPerViewIncomponents, slidesInDynamicAccess };

const React = require('react');

const { string, func } = require('prop-types');

const PaymentsMethodsV2 = ({ onLoad, targetModal }) => (
  <iframe
    id="payment-methods_v2"
    className="payment-methods_v2"
    title="payments-methods"
    onLoad={onLoad}
    style={{
      border: 'none',
      height: '80%',
    }}
    width="100%"
    src={targetModal}
    scrolling="yes"
  />
);

PaymentsMethodsV2.propTypes = {
  onLoad: func,
  targetModal: string.isRequired,
};
PaymentsMethodsV2.defaultProps = {
  onLoad: null,
  targetModal: null,
};

module.exports = React.memo(PaymentsMethodsV2);

